import firebase from 'firebase/compat/app';
import { throttle } from 'lodash';
import 'firebase/compat/database'; // Import the Realtime Database SDK

firebase.initializeApp({
    apiKey: "AIzaSyAHOx0yKubVBeZOEMMDC-E3Gl3kmbHFBIM",
    authDomain: "vote-the-goat.firebaseapp.com",
    databaseURL: "https://vote-the-goat-default-rtdb.europe-west1.firebasedatabase.app/",
    projectId: "vote-the-goat",
    storageBucket: "vote-the-goat.appspot.com",
    messagingSenderId: "161458053158",
    appId: "1:161458053158:web:71c00d69d52a3a401046e7",
    measurementId: "G-XG7YGBD578"
  });
  

const db = firebase.database();

const throttledAdd = throttle(async (id, value) => {
  await db.ref(`votes/${id}`).transaction(currentData => {
    if (currentData) {
      currentData.votes += value;
    } else {
      currentData = { votes: value };
    }
    return currentData;
  }, error => {
    if (error) {
      console.log(error);
    }
  });
}, 1000);

const throttledDecrease = throttle(async (id, value) => {
  await db.ref(`votes/${id}`).transaction(currentData => {
    if (currentData) {
      currentData.votes += value;
    } else {
      currentData = { votes: value };
    }
    return currentData;
  }, error => {
    if (error) {
      console.log(error);
    }
  });
}, 1000);

const throttledGetVotes = throttle(async (id) => {
  const snapshot = await db.ref(`votes/${id}`).once('value');
  const data = snapshot.val();
  return data ? data.votes : 0;
}, 5000);

const throttledGetVotess = throttle(async () => {
  try {
    const snapshot = await db.ref('votes').once('value');
    const data = snapshot.val();
    return data || {};
  } catch (error) {
    console.log(error);
    return {};
  }
}, 5000);

const throttledSubscribeVotes = throttle((setVotes) => {
  const votesRef = db.ref('votes');
  votesRef.on('value', (snapshot) => {
    console.log("received votes snapshot")
    const newVotes = snapshot.val() || {};
    setVotes(newVotes);
  });
  return () => votesRef.off();
}, 5000);

export { throttledAdd, throttledDecrease, throttledGetVotes, throttledGetVotess, throttledSubscribeVotes };