import './App.css';
import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';
import Ranking from './components/Ranking';

function App() {

  const [goats, setGoats] = useState([]);

  useEffect(() => {

    fetch('goats.json')
      .then(response => response.json())
      .then(data => {
        setGoats(data.goats);
      }).catch(error => console.log(error));

  }, []);

  return (

    <>
      <Helmet>
        <title>Goat Ranking - Who is really the Greatest Of All Times? </title>
        <meta name="description" content="" />
        <link rel="canonical" href="https://www.vote-the-goat.com" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className="App">
        <header className="App-header">
          <div id="amzn-assoc-ad-5780916f-3f83-44af-86ee-c03da2240e13"></div><script async src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=5780916f-3f83-44af-86ee-c03da2240e13"></script>
          <div className="logo-container">
            <div className="logo-image">
              <img src="goat_logo.png" alt="vote-the-goat.com logo" className="responsive-logo" />
            </div>

          </div>
          <Ranking kickers={goats} />
        </header>
        {/* <AsciiBanner/> */}
      </div>
    </>
  );
}

export default App;
