import React from 'react';
import './Ranking.css';
import { useState, useEffect, useRef, useCallback } from 'react';
import 'firebase/compat/firestore';
import { } from 'firebase/compat/app-check';
import { throttledAdd, throttledDecrease, throttledSubscribeVotes } from './DataStore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const appCheck = firebase.appCheck();
appCheck.activate(
  '6LeyiEElAAAAAIBV7ug1k3xkEkzVND4LzhetRUeN',
  true); // true = refresh SDK token as needed

function Ranking({ kickers }) {
  const [sortedGoats, setSortedGoats] = useState([]);
  const scrollRef = useRef(null);
  const [votes, setVotes] = useState([]);
  const [animateKarma, setAnimateKarma] = useState(false); //for animation


  const handleVote = useCallback(async (id, increment) => {
    /*creates a new array from the previous votes state, updates the votes value at the specified index, and returns the new votes array.*/
    setVotes((prevVotes) => ({
      ...prevVotes,
      [id]: { votes: (prevVotes[id]?.votes || 0) + increment },
    }));

    try {
      await increment > 0
        ? throttledAdd(id, increment)
        : throttledDecrease(id, increment);
    } catch (error) {
      console.log(error);
    }

    // Trigger the animation
    setAnimateKarma(true);
    setTimeout(() => setAnimateKarma(false), 500);

  }, []);


  useEffect(() => {
    const unsubscribe = throttledSubscribeVotes(voteData => {
      console.log(voteData);
      setVotes(voteData);
    });

    return () => {
      unsubscribe();
    };
  }, []);


  /* sorting the person list according to their votes */
  useEffect(() => {
    setSortedGoats(
      kickers
        .slice()
        .sort((a, b) => (votes[b.id]?.votes || 0) - (votes[a.id]?.votes || 0))
    );
  }, [votes, kickers]);




  /* helping methods */
  const getColor = (votes) => {
    if (votes === null || votes === undefined || votes === 0) {
      return 'black';
    } else if (votes < 0) {
      return 'darkred';
    } else {
      return 'darkgreen';
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: scrollRef.current.scrollHeight,
      behavior: 'smooth'
    });
  }

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };


  return (
    <div className="Rankings-container">
      <h2>Live GOAT Ranking </h2>
      Vote the Goat! updated in Realtime <br></br>
      <br></br>
      <div className="link btn" onClick={scrollToBottom}>
        Scroll to bottom
      </div> <br></br>
      {sortedGoats.length === 0 ? (
        <p>Loading...</p>
      ) : (
        <table>
          <tbody>
            {sortedGoats.map((person, index) => (
              <tr key={person.id}>
                <td>
                  <div style={{ textAlign: 'center' }}>
                    <button className="vote-button-up" onClick={() => handleVote(person.id, 1)}>upGoat</button>
                    <button className="vote-button-down" onClick={() => handleVote(person.id, -1)}>downGoat</button>
                  </div>
                  <div
                    className={`karma-counter ${animateKarma ? "animate-grow-fade" : ""}`}
                    style={{ textAlign: "center" }}
                  >
                    <span
                      style={{
                        color: getColor(votes[person.id]?.votes || 0),
                        fontWeight: "bold",
                      }}
                    >
                      {votes[person.id]?.votes || 0} GOATS
                    </span>
                  </div>
                </td>
                <td> <div className="profilePic"><img src={person.image_path} alt={person.name}></img></div></td>
                <td><h1>{person.name}</h1></td>
                <td style={{ fontWeight: 'bold' }}>{person.dob ? Math.round((Date.now() - new Date(person.dob)) / (1000 * 60 * 60 * 24 * 365)) + " years old" : "unknown"}</td>
                <td style={{ fontWeight: 'lighter' }}> {person.birthplace}</td>
                <td style={{ fontWeight: 'italic' }}> {person.nicknames}</td>
                <td> {person.position}</td>
                <td style={{ fontWeight: 'bold' }}><h3>{person.current_club}</h3></td>
                <td style={{ fontStyle: 'italic' }}>{person.achievements}</td>
                <td> {person.records}</td>
                <td> net worth ~ <div style={{ fontWeight: 'bold' }}>{person.net_worth ? person.net_worth + " million $" : "0 $"}</div></td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div ref={scrollRef} style={{ height: '100%', overflow: 'auto' }}>
        &nbsp;
      </div>
      <div name="footer">
        <a href="#" onClick={scrollToTop}>Jump to Top</a><br></br><br></br>
        2023 by Vote-The-Goat.com<br></br><br></br>
        also checkout <a href="https://www.karmarankings.com">KarmaRankings.com</a> for global Karma Rankings <br></br>
        Anonymous Vote data is stored <br></br>
        Google Firebase and Analytics<br></br>
        Google Ads<br></br>
      </div>
    </div>
  );
}

export default Ranking;